.loader{
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--dark);
    z-index: 9999999999999;
}
.blinker {
    animation: blink 1.5s linear infinite;
}
@keyframes blink {
    25% {
        opacity: 0.5;
    }
    50% {
        opacity: 0.15;
    }
    75% {
        opacity: 0.5;
    }
}
