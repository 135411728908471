.container {
    height: calc(100dvh - 120px);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    position: relative;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.content h1,
.content p {
    text-align: center;
}

.content h1 {
    color: var(--dark);
    text-align: center;
    font-size: 3.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    user-select: none;
}

.content p {
    font-size: 1.125rem;
}

.buttonContainer {
    display: flex;
    justify-content: center;
}

.buttonContainer a {
    text-decoration: none;
    user-select: none;
}

@media (min-width: 768px) {
    .content h1 {
        font-size: 6.25rem;
    }

    .content p {
        font-size: 1.375rem;
    }

    .content {
        gap: 38px;
    }
}
