.desktop {
    background: #0E171F;
    position: relative;
}
.dark.desktop,
.dark.mobile{
    background: var(--dark);
}
.mobile {
    background: var(--white);
    position: relative;
}
.container{
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dark.desktop .container{
    padding: 24px 0;
    justify-content: normal;
}
.titleContainer{
}
.titleContainer p{
    color: var(--dark);
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 600;
    opacity: .3;
    line-height: 140%; /* 19.6px */
    text-wrap: nowrap;
}

@media (min-width: 768px) {
    .titleContainer p{
        font-size: 1vw;
    }

}
.dark .titleContainer p{
    color: rgba(255, 255, 255, 0.70);
}
.mobile .titleContainer p{
    font-size: 12px;
}
.sliderContainer{
    overflow: hidden;
    position: relative;
    height: 25px;
}
.sliderLeftShadow{
    position: absolute;
    left: 0;
    top: 0;
    width: 7vw;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.90) 0.12%, rgba(255, 255, 255, 0.00) 99.63%);
}
.dark .sliderLeftShadow{
    background: linear-gradient(90deg, rgba(5, 5, 13, 0.90) 0.37%, rgba(5, 5, 13, 0.00) 99.88%);
}
.sliderRightShadow{
    position: absolute;
    right: 0;
    top: 0;
    width: 7vw;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.90) 0.06%, rgba(255, 255, 255, 0.00) 99.98%);
}
.dark .sliderRightShadow{
    background: linear-gradient(270deg, #05050D 0.06%, rgba(5, 5, 13, 0.00) 99.98%);
}
.slider{
    overflow: hidden;
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding-left: 40px;
    justify-content: space-between;
    align-items: center;
    animation: slider 60s linear infinite running;
    will-change: transform;
    transform: translate3d(0,0,0);
}
.slider:hover{
    animation-play-state: paused;
}
.dark .slider{
    gap: 54px;
    padding-left: 54px;
}
.icon{
    cursor: pointer;
}
.icon path{
    transition: fill-opacity 300ms ease;
}
.icon:hover path{
    fill-opacity: 1;
}
.dark .icon path{
    fill: var(--white)
}

@keyframes slider {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translate(-50%);
    }
}
