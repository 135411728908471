.greenButton{
    display: flex;
    align-items: center;
    gap: 25px;
    color: var(--dark);
    border-radius: 5px;
    border: none;
    text-align: center;
    font-size: 1.25rem; /* 20px */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    overflow: hidden;
    flex-direction: column;
    position: relative;
    min-width: 300px;
}

@media (max-width: 768px) {
    .greenButton{
        justify-content: center;
        padding: 0 20px;
        min-height: 50px;
    }
    .greenButton p, .greenButton span {
        font-size: 1.125rem;
    }
}

.greenButton svg{
    -webkit-transition: transform 300ms ease-in-out;
    -moz-transition: transform 300ms ease-in-out;
    -o-transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
}
.greenButton:hover svg{
    transform: translateX(10px);
}
.greenButton:hover {
    cursor: pointer;
}
.greenButton .buttonText{
    z-index: 1;
    display: flex;
    gap: 15px;
    align-items: center;
}
.greenButton .gradient{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    -webkit-transition: opacity 300ms ease-in-out;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out;
    background: linear-gradient(40deg, rgba(145,171,255,1) 10%, rgba(67,243,254,1) 35%, rgba(51,255,113,1) 65%, rgba(142,252,56,1) 90%);
}
.greenButton:hover .gradient{
    opacity: 1;
}
.link{
    text-decoration: none !important;
}
